import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";

export function useStores({ searchState, page }) {
  const { storeName, ownerName, storeStatus, storeAcceptStatus } = searchState;

  const { data: stores,refetch } = useQuery(
    ["stores",storeName, ownerName, storeStatus, storeAcceptStatus, String(page)],
    async () => {
      const { data } = await apiClient({
        url: "/stores",
        method: "get",
        params: {
          ...(storeName && { storeName }),
          ...(ownerName && { ownerName }),
          ...(storeStatus && { storeStatus }),
          ...(storeAcceptStatus && { storeAcceptStatus }),
          page,
        },
      });

      return data;
    },
    {
      keepPreviousData: false,
    }
  );

  return { stores, refetch };
}
