import { useEffect, useState, useCallback } from "react";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { ko } from "date-fns/esm/locale";
import { Suspense } from "react";
import { transformYYYYMMDD } from "../../function/date";
import LeftMenu from "../../components/admin/LeftMenu";
import Btn from "../../components/admin/Btn";
import DetailPagination from "../../components/admin/DetailPagination";
import SmartStoreBoard from "../../components/smartStore/SmartStoreBoard";

export default function SmartStore() {
  const location = useLocation();
  const { pageQuery, startDateQuery, endDateQuery } = getQueryStringObj();
  const navigate = useNavigate();

  const [page, setPage] = useState(pageQuery || 0);

  const [totalPage, setTotalPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [showingDate, setShowingDate] = useState({
    start: startDateQuery || new Date(),
    end: endDateQuery || new Date(),
  });
  const [smartStoreDate, setSmartStoreDate] = useState({
    start: startDateQuery || new Date(),
    end: endDateQuery || new Date(),
  });

  function getQueryString() {
    return location.search
      .replace(/\?page=|&startDate=|&endDate=/g, " ")
      .split(" ");
  }

  function getQueryStringObj() {
    const [_, pageQuery, startDateQuery, endDateQuery] = getQueryString();

    if (
      !pageQuery === undefined ||
      startDateQuery === undefined ||
      endDateQuery === undefined
    ) {
      return {
        pageQuery: "",
        startDateQuery: new Date(),
        endDateQuery: new Date(),
      };
    }

    return {
      pageQuery: decodeURI(pageQuery),
      startDateQuery: new Date(decodeURI(startDateQuery)),
      endDateQuery: new Date(decodeURI(endDateQuery)),
    };
  }

  const setPageStatus = useCallback(() => {
    const [_, page, startDate, endDate] = getQueryString();

    if (!page) {
      setPage(0);
      setSmartStoreDate({ start: new Date(), end: new Date() });
      setShowingDate({ start: new Date(), end: new Date() });
      return;
    }

    setPage(page);
    setSmartStoreDate({ start: new Date(startDate), end: new Date(endDate) });
    setShowingDate({ start: new Date(startDate), end: new Date(endDate) });
  }, [setPage, setSmartStoreDate, setShowingDate, location]);

  useEffect(() => {
    return () => sessionStorage.clear();
  }, []);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  // if (!payments) {
  //   return null;
  // }

  const getNavigateStr = (page = 0) => {
    return `?page=${page}&startDate=${transformYYYYMMDD(
      smartStoreDate.start
    )}&endDate=${transformYYYYMMDD(smartStoreDate.end)}`;
  };

  const handleSearchClick = () => {
    setShowingDate({ ...smartStoreDate });
    navigate(getNavigateStr());
  };

  const handlePageMove = (page) => {
    navigate(getNavigateStr(page));
  };

  return (
    <>
      <Container fluid>
        <Row style={{ flexWrap: "nowrap" }}>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">스마트 스토어 주문 내역</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <Row className="p-1 align-items-center justify-content-center">
                <Col xs={12} lg={"auto"} className="my-3">
                  <Row className="align-items-center justify-content-center">
                    <Col xs={"auto"}>
                      <h5 className="mb-0 fw-bold"> 결제일</h5>
                    </Col>
                    <Col xs={"auto"}>
                      <DatePicker
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        selected={smartStoreDate.start}
                        onChange={(date) => {
                          if (smartStoreDate.end < date) {
                            setSmartStoreDate({
                              start: smartStoreDate.end,
                              end: date,
                            });
                            return;
                          }

                          setSmartStoreDate((prev) => ({
                            ...prev,
                            start: date,
                          }));
                        }}
                      />
                    </Col>
                    <Col xs={"auto"}>
                      <h5 className="mb-0"> ~ </h5>
                    </Col>
                    <Col xs={"auto"}>
                      <DatePicker
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        selected={smartStoreDate.end}
                        onChange={(date) => {
                          if (smartStoreDate.start > date) {
                            setSmartStoreDate({
                              start: date,
                              end: smartStoreDate.start,
                            });
                            return;
                          }

                          setSmartStoreDate((prev) => ({ ...prev, end: date }));
                        }}
                      />
                    </Col>
                    <Col xs={"auto"}>
                      <Btn type="API" btntext="조회 하기" onClick={1} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{
                  verticalAlign: "middle",
                }}
                className="mb-4 text-center"
                responsive
              >
                <thead>
                  <tr>
                    <th>주문번호</th>
                    <th>주문상태</th>
                    <th>상품주문번호</th>
                    <th>수량</th>
                    <th>상품명</th>
                    <th>상품가격</th>
                    <th>판매상태</th>
                    <th>단가</th>
                    <th>총결제금액</th>
                    <th>총상품금액</th>
                    <th>주문자명</th>
                    <th>수령인</th>
                    <th>수령인 전화번호</th>
                    <th>쿠폰 <br/>발행수량</th>
                    <th>결제일</th>
                    {/*<th>배송메모</th>*/}
                    <th>취소</th>
                    {/*<th>반품승인</th>*/}
                  </tr>
                </thead>
                <tbody className="text-center" style={{ position: "relative" }}>
                  <Suspense
                    fallback={
                      <tr style={{ height: "250px" }}>
                        <Spinner
                          as={"td"}
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            marginLeft: "-18px",
                          }}
                          animation="border"
                        />
                      </tr>
                    }
                  >
                    <SmartStoreBoard
                      page={page}
                      showingDate={showingDate}
                      setTotalPage={setTotalPage}
                      setTotalElements={setTotalElements}
                    />
                  </Suspense>
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={totalPage}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
