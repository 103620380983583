import { Link } from "react-router-dom";
import useSmartStores from "../../hooks/smartStore/useSmartStores";
import {Button} from "react-bootstrap";
import adminApi from "../../function/apiConfig";

const Td = ({ children }) => {
  return (
    <td
      style={{
        wordBreak: "keep-all",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </td>
  );
};
const clickCancel = (_productOrderId)=>{
    let _message = "취소승인 : 스마트스토어에서 구매자가 취소 요청을 한경우\n"
    _message += "반품승인 : 스마트스토어에서 구매자가 반품 요청을 한경우\n"
    _message += "DB취소 : 스마트스토어 상태와 관계없이 DB상에서 취소처리\n"
    _message += "-> 이미 스마트스토어에서 취소처리 한경우에 이용\n\n"
    _message += "취소승인, 반품승인, DB취소 중 하나를 입력해주세요"
    const _type = prompt(_message);
    if(!_type){
        return null;
    }
    if(_type == "취소승인"){
        clickApprove('cancel',_productOrderId);
    }else if(_type == "반품승인"){
        clickApprove('return',_productOrderId);
    }else if(_type == "DB취소"){
        clickApprove('db',_productOrderId);
    }else{
        alert("유효하지 않은 입력값 입니다.");
    }

}

const clickApprove = (type, productOrderId)=>{
    const _approve = confirm("요청을 승인 하시겠습니까?");
    if(!_approve){
        return null;
    }
    adminApi({
        url: "/smart-store/orders/"+productOrderId,
        method: "delete",
        data:{type:type},
        customOption: { errorPopup: true },
    })
    .then(function (response) {
        alert('취소를 완료하였습니다.');
    })
    .catch(function (error) {
        console.log(error);
    });
}

export default function SmartStoreBoard({
  page,
  showingDate,
  setTotalPage,
  setTotalElements,
}) {
  const { smartStores } = useSmartStores({
    showingDate,
    page,
    setTotalPage,
    setTotalElements,
  });

  const mappedOrderInfo = smartStores.content.map((store) => {
    const {
      createdCouponQuantity,
      orderId,
      orderStatus = {
        code: "",
        description: "",
        name: "",
      },
      ordererName,
      paymentDate,
      product = {
        description: "",
        menuType: { code: "", description: "" },
        name: "",
        price: "",
        productId: "",
        representImageUrl: "",
        status: {
          code: "",
          description: "",
          name: "",
        },
      },
      productOrderId,
      quantity,
      receiverName,
      receiverPhoneNumber,
      shippingMemo,
      totalPaymentAmount,
      totalProductAmount,
      unitPrice,
    } = store;

    return (
      <tr key={orderId} className="board-row-except">
        <Td>{orderId}</Td>
        <Td>{orderStatus?.description}</Td>
        <Td>{productOrderId}</Td>
        <Td>{quantity}</Td>
        <Td>{product?.name}</Td>
        <Td>{product?.price}</Td>
        <Td>{product?.status?.description}</Td>
        <Td>{unitPrice}</Td>
        <Td>{totalPaymentAmount}</Td>
        <Td>{totalProductAmount}</Td>
        <Td>{ordererName}</Td>
        <Td>{receiverName}</Td>
        <Td>{receiverPhoneNumber}</Td>
        <Td>{createdCouponQuantity}</Td>
        <Td>{paymentDate?.replace("T", " ").substring(0, 16)}</Td>
        {/*<Td>{shippingMemo}</Td>*/}
        <Td><Button  onClick={()=> clickCancel(productOrderId)}>취소</Button> </Td>
        {/*<Td><Button  onClick={()=> clickApprove('cancel',productOrderId)}>취소 승인</Button> </Td>*/}
        {/*<Td><Button onClick={()=> clickApprove('return',productOrderId)}>반품 승인</Button></Td>*/}
      </tr>
    );
  });

  return mappedOrderInfo;
}
