import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AdminLogin from "./pages/admin/main/AdminLogin";
import BoardDetail from "./pages/admin/main/BoardDetail";
import Stores from "./pages/admin/store/Stores";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./components/common/PrivateRoute";
import StoreDetail from "./pages/admin/store/StoreDetail";
import StoreProduct from "./pages/admin/store/StoreProduct";
import UserBoard from "./pages/admin/user/UserBoard";
import StoreUserBoard from "./pages/admin/user/StoreUserBoard";
import CouponDetail from "./pages/admin/coupon/CouponDetail";
import { Stack } from "react-bootstrap";
import { Suspense } from "react";
import CouponLogs from "./pages/admin/coupon/CouponLogs";
import CouponPayments from "./pages/admin/coupon/CouponPayments";
import Payments from "./pages/admin/payment/Payments";
import Settlements from "./pages/admin/settlement/Settlements";
import Coupons from "./pages/admin/coupon/Coupons";
import ErrorBoundary from "./components/common/ErrorBoundary";
import CustomSpinner from "./components/common/CustomSpinner";
import PaymentDetail from "./pages/admin/payment/PaymentDetail";
import UserCouponDetail from "./pages/admin/coupon/UserCouponDetail";
import SignUp from "./pages/admin/signup/SignUp";
import Admin from "./pages/admin/admin/Admin";
import DashBoard from "./pages/admin/main/DashBoard";
import DisplayBoard from "./pages/admin/store/DisplayBoard";
import SmartStore from "./pages/smartStore/SmartStore";
import PaymentSummary from "./pages/admin/payment/PaymentSummary";
import PaymentKiosk from "./pages/admin/payment/PaymentKiosk";
import ManageKiosk from "./pages/admin/manageKiosk/ManageKiosk";
import CreateKiosk from "./pages/admin/manageKiosk/CreateKiosk";
import ManageKioskDetail from "./pages/admin/manageKiosk/ManageKioskDetail";
import StoreConnect from "./pages/admin/store/StoreConnect";
import ProductChain from "./pages/admin/product/chain/ProductChain";
import ProductCommerce from "./pages/admin/product/ProductCommerce";
import ProductLog from "./pages/admin/product/ProductLog";
import ManageNotice from "./pages/admin/manageNotice/ManageNotice";
import CreateNotice from "./pages/admin/manageNotice/CreateNotice";
import ManageNoticeDetail from "./pages/admin/manageNotice/ManageNoticeDetail";
import ManageFaq from "./pages/admin/manageFaq/ManageFaq";
import CreateFaq from "./pages/admin/manageFaq/CreateFaq";
import ManageFaqDetail from "./pages/admin/manageFaq/ManageFaqDetail";
import PhysicalCard from "./pages/admin/product/physicalCard/PhysicalCard";
import PhysicalCardDetail from "./pages/admin/product/physicalCard/PhysicalCardDetail";
import PhysicalCardCreate from "./pages/admin/product/physicalCard/PhysicalCardCreate";
import Inbounds from "./pages/admin/store/Inbounds";
import { isAuthedUser } from "./function/common";
import HomeReplace from "./pages/HomeReplace";
import StoreGroup from "./pages/admin/store/StoreGroup";
import StoreGroupDetail from "./pages/admin/store/StoreGroupDetail";
import ManageDoorSystem from "./pages/admin/manageDoorSystem/ManageDoorSystem";
import DoorSystemDetail from "./pages/admin/manageDoorSystem/DoorSystemDetail";
import ManageUpdateNotice from "./pages/admin/manageUpdateNotice/ManageUpdateNotice";
import CreateUpdateNotice from "./pages/admin/manageUpdateNotice/CreateUpdateNotice";
import ManageUpdateNoticeDetail from "./pages/admin/manageUpdateNotice/ManageUpdateNoticeDetail";

function App() {
  const appVersion = process.env.REACT_APP_VERSION;
  function logout() {
    localStorage.clear();
    window.location.href = "/admin/login";
  }

  const Header = () => {
    if (!isAuthedUser()) return null;
    return (
      <header className="App-header pt-4 px-5 ">
        <Stack direction="horizontal" gap={3}>
          <div className="border border-0 ms-auto px-3">
            {process.env.REACT_APP_VERSION}
          </div>
        </Stack>
        <Stack direction="horizontal" gap={3}>
          <div
            onClick={logout}
            className="border border-0 ms-auto px-3 text-white"
            style={{
              height: "30px",
              lineHeight: "30px",
              borderRadius: "50px",
              background: "#333333",
              cursor: "pointer",
            }}
          >
            로그 아웃
          </div>
        </Stack>
      </header>
    );
  };

  return (
    <div className="App">
      <Header />
      <main>
        <Router>
          <Suspense fallback={<CustomSpinner />}>
            <ErrorBoundary>
              <Routes>
                {isAuthedUser() ? (
                  <>
                    <Route path="/" element={<DashBoard />} />
                    <Route
                      path="/admin/boarddetail/:id"
                      element={<BoardDetail />}
                    />
                    <Route path="/admin/admins/users" element={<Admin />} />
                    <Route path="/admin/store/stores" element={<Stores />} />
                    <Route
                      path="/admin/store/displays"
                      element={<DisplayBoard />}
                    />
                    <Route path="/admin/store/group" element={<StoreGroup />} />
                    <Route
                      path="/admin/store/group/detail"
                      element={<StoreGroupDetail />}
                    />
                    <Route
                      path="/admin/store/connect"
                      element={<StoreConnect />}
                    />
                    <Route
                      path="/admin/store/inbounds-log"
                      element={<Inbounds />}
                    />
                    <Route
                      path="/admin/settlement/fee"
                      element={<Settlements />}
                    />
                    <Route path="/admin/user/users" element={<UserBoard />} />
                    <Route
                      path="/admin/user/store-users"
                      element={<StoreUserBoard />}
                    />
                    <Route
                      path="/admin/store/stores/:id"
                      element={<StoreDetail />}
                    />
                    <Route
                      path="/admin/product/products"
                      element={<StoreProduct />}
                    />
                    <Route
                      path="/admin/product/chain-products"
                      element={<ProductChain />}
                    />
                    <Route
                      path="/admin/product/commerce-products"
                      element={<ProductCommerce />}
                    />
                    <Route
                      path="/admin/product/product-logs"
                      element={<ProductLog />}
                    />
                    <Route
                      path="/admin/product/physical-card"
                      element={<PhysicalCard />}
                    />
                    <Route
                      path="/admin/product/physical-card/create"
                      element={<PhysicalCardCreate />}
                    />
                    <Route
                      path="/admin/product/physical-card/detail/:storeId/:id"
                      element={<PhysicalCardDetail />}
                    />
                    <Route path="/admin/coupon/coupons" element={<Coupons />} />
                    <Route
                      path="/admin/coupon/coupons/:couponId"
                      element={<CouponDetail />}
                    />
                    <Route
                      path="/admin/coupon/coupons/user/:userid"
                      element={<UserCouponDetail />}
                    />
                    <Route
                      path="/admin/coupon/coupon-logs"
                      element={<CouponLogs />}
                    />
                    <Route
                      path="/admin/coupon/coupon-payments"
                      element={<CouponPayments />}
                    />
                    <Route
                      path="/admin/payment/payment-summaries"
                      element={<PaymentSummary />}
                    />
                    <Route
                      path="/admin/payment/kiosk"
                      element={<PaymentKiosk />}
                    />
                    <Route
                      path="/admin/payment/payments"
                      element={<Payments />}
                    />
                    <Route
                      path="/admin/payment/payments/:orderNumber"
                      element={<PaymentDetail />}
                    />
                    <Route
                      path="/admin/payment/smartStore"
                      element={<SmartStore />}
                    />
                    <Route path="/admin/kiosks">
                      <Route path="" element={<ManageKiosk />} />
                      <Route path="create" element={<CreateKiosk />} />
                      <Route
                        path="detail/:id"
                        element={<ManageKioskDetail />}
                      />
                    </Route>
                    <Route path="/admin/notices">
                      <Route path="" element={<ManageNotice />} />
                      <Route path="create" element={<CreateNotice />} />
                      <Route
                        path="detail/:id"
                        element={<ManageNoticeDetail />}
                      />
                    </Route>
                    <Route path="/admin/updateNotices">
                      <Route path="" element={<ManageUpdateNotice />} />
                      <Route path="create" element={<CreateUpdateNotice />} />
                      <Route
                        path="detail/:id"
                        element={<ManageUpdateNoticeDetail />}
                      />
                    </Route>
                    <Route path="/admin/faqs">
                      <Route path="" element={<ManageFaq />} />
                      <Route path="create" element={<CreateFaq />} />
                      <Route path="detail/:id" element={<ManageFaqDetail />} />
                    </Route>
                    <Route path="/admin/doorSystems">
                      <Route path="" element={<ManageDoorSystem />} />
                      <Route path="create" element={<DoorSystemDetail />} />
                      <Route path="detail/:id" element={<DoorSystemDetail />} />
                    </Route>
                  </>
                ) : (
                  <>
                    <Route path="/" element={<AdminLogin />} />
                    <Route path="/admin/signup" element={<SignUp />} />
                  </>
                )}
                <Route path="*" element={<HomeReplace />} />
                {/* <Route path="*" element={<NotFound />} /> */}
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </Router>
      </main>
    </div>
  );
}

export default App;
