import { Suspense } from "react";
import { useState, useEffect, useCallback } from "react";
import { Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Btn from "../../../components/admin/Btn";
import DetailPagination from "../../../components/admin/DetailPagination";
import LeftMenu from "../../../components/admin/LeftMenu";
import CustomModal from "../../../components/common/CustomModal";
import CustomSpinner from "../../../components/common/CustomSpinner";
import SettlementBoard from "../../../components/settlement/SettlementBoard";
import adminApi from "../../../function/apiConfig";
import { useGetExcelDownload } from "../../../hooks/excel/useGetExcelDownload";
import SpinnerPortal from "../../../portals/SpinnerPortal";

export default function Settlements() {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(sessionStorage.getItem("page") || 0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [modal, setModal] = useState({ showModal: false });
  const [showingStoreName, setShowingStoreName] = useState(
    sessionStorage.getItem("storeName") || ""
  );
  const [storeName, setStoreName] = useState(
    sessionStorage.getItem("storeName") || ""
  );
  const { mutateGetExcel, isGetExcelLoading } = useGetExcelDownload();

  const setPageStatus = useCallback(() => {
    const [_, page, startDate, endDate, storeName] = location.search
      .replace(/\?page=|&startDate=|&endDate=|&storeName=/g, " ")
      .split(" ");

    if (!page) {
      setPage(0);
      setStoreName("");
      return;
    }

    setPage(page);
    setStoreName(decodeURIComponent(storeName));
    setShowingStoreName(decodeURIComponent(storeName));
  }, [setPage, setStoreName, setShowingStoreName, location]);

  const [checkedIdSet, setCheckedIdSet] = useState(new Set([]));

  useEffect(() => {
    return () => sessionStorage.clear();
  }, []);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  const getNavigateStr = (page = 0) => {
    return `?page=${page}&startDate=22-06-11&endDate=30-10-12&storeName=${storeName}`;
  };

  const handleSearchClick = () => {
    setShowingStoreName(storeName);
    navigate(getNavigateStr());
  };

  const handleInputKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    handleSearchClick();
  };

  const handlePageMove = (page) => {
    navigate(getNavigateStr(page));
  };

  //   레거시
  function openDownloadModal(type) {
    console.log(type);
    //토스 지급대행
    if (type == "PAYMENT_AGENT") {
      const modalContent = {
        callbackOfOk: () => downloadExcel(type),
        textOfBody: "지급대행 csv를 다운로드 하시겠습니까?",
        showModal: true,
      };
      setModal(modalContent);
      //계좌이체
    } else if (type == "ACCOUNT_TRANSFER") {
      const modalContent = {
        callbackOfOk: () => downloadExcel(type),
        textOfBody: "신한은행 대량이체 양식을 다운로드 하시겠습니까?",
        showModal: true,
      };
      setModal(modalContent);
    } else if (type == "TAX_BILL") {
      const modalContent = {
        callbackOfOk: () => downloadExcel(type),
        textOfBody: "주별 세금계산서를 다운로드 하시겠습니까?",
        showModal: true,
      };
      setModal(modalContent);
    }
  }
  function settleAgentRequestAlert() {
    if (checkedIdSet.size == 0) {
      alert("정산 대행할 건들을 선택해주세요");
    } else {
      let settleDate;

      settleDate = prompt("지급일을 입력해주세요 ex)2023-10-26");
      if (!settleDate) return;

      console.log(checkedIdSet);
      adminApi({
        url: "/settlements/process",
        data: {
          settlementIds: Array.from(checkedIdSet),
          payoutDate: settleDate,
        },
        method: "post",
      }).then(function (response) {
        let message = "정산대행 api 요청이 완료되었습니다.\n";
        message += "요청 건수 :" + response.countOfRequest + "\n";
        message += "성공 건수 :" + response.countOfSuccess + "\n";
        message += "실패 사유 \n";
        message += response.errorMessages.join("\n");
        alert(message);
        setCheckedIdSet(new Set());
        setPage(0);
      });
    }
  }

  function settleAgentCompleteAlert() {
    if (checkedIdSet.size == 0) {
      alert("정산 대행 완료처리할 건들을 선택해주세요");
    } else {
      let _checked = window.confirm("정산 예정일 기준으로 완료 처리 됩니다.");
      if (!_checked) return;

      adminApi({
        url: "/settlements/agent/complete",
        data: {
          settleIds: Array.from(checkedIdSet),
        },
        method: "post",
      }).then(function (response) {
        let message = "정산대행이 완료 처리되었습니다.\n";
        message += "요청 건수 :" + response.countOfRequest + "\n";
        message += "성공 건수 :" + response.countOfSuccess + "\n";
        alert(message);
        setCheckedIdSet(new Set());
        setPage(0);
      });
    }
  }

  function downloadExcel(type) {
    let settleEndDate;
    let paymentDueDate;
    let year;
    let weekOfYear;
    let issueTaxInvoiceDate;

    if (type != "TAX_BILL") {
      settleEndDate = prompt("정산 마감일을 입력해주세요 ex)22-10-26");
      if (!settleEndDate) return;

      paymentDueDate = prompt("지급예정일을 입력해주세요 ex)22-10-26");
      if (!paymentDueDate) return;
    } else {
      year = prompt("세금계산서를 발행할 정산년도를 입력해주세요 ex) 2023");
      if (!year) return;

      weekOfYear = prompt("세금계산서를 발행할 정산 주차를 입력해주세요 ex)22");
      if (!weekOfYear) return;

      issueTaxInvoiceDate = prompt(
        "세금계산서 발행(예정)일을 입력해주세요 ex)2022-10-26"
      );
      if (!issueTaxInvoiceDate) return;
    }

    let requestParams = {
      paymentDueDate: paymentDueDate,
      settleEndDate: settleEndDate,
    };

    adminApi({
      url: "/settlements/excel",
      params: requestParams,
      data: {
        excelType: type,
        year: year,
        weekOfYear: weekOfYear,
        issueTaxInvoiceDate: issueTaxInvoiceDate,
      },
      method: "post",
      customOption: { fileDownLoad: true },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const blob = new Blob([response.data]);
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.style.display = "none";
        link.download = getFileName(response);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(function (error) {});
  }

  function getFileName(response) {
    const disposition = response.headers["content-disposition"];

    const fileName = decodeURI(
      disposition
        .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
        .replace(/['"]/g, "")
    );
    return fileName;
  }

  const handleClickTaxBillExcelDownload = () => {
    if (isGetExcelLoading) {
      return;
    }

    const result = window.prompt(`년, 월을 입력해주십시오. ex)2023, 03`);
    const regexp = new RegExp(/^\d{4},[ ]?\d{2}$/);

    if (!regexp.test(result)) {
      alert("양식에 맞게 입력해주십시오.");
      return;
    }
    const [year, month] = result.split(",");

    mutateGetExcel({
      url: "/settlements/excel/tax-bill",
      params: { year: year.trim(), month: month.trim() },
    });
  };

  return (
    <>
      {isGetExcelLoading && (
        <SpinnerPortal>
          <CustomSpinner type="backdrop" />
        </SpinnerPortal>
      )}
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">수수료 정산!</h1>
              </Col>
            </Row>

            <Row className="p-1">
              {/* 2개형 */}
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
              <Row className="p-1 justify-content-end">
                {/*<Col xs={"auto"} className="btn-black">*/}
                {/*  <Btn*/}
                {/*    type="API"*/}
                {/*    btntext="정산대행 엑셀 다운"*/}
                {/*    onClick={() => openDownloadModal("PAYMENT_AGENT")}*/}
                {/*  />*/}
                {/*</Col>*/}
                {/*<Col xs={"auto"} className="btn-black">*/}
                {/*  <Btn*/}
                {/*    type="API"*/}
                {/*    btntext="대량이체 엑셀 다운"*/}
                {/*    // onClick={() => openDownloadModal("ACCOUNT_TRANSFER")}*/}
                {/*  />*/}
                {/*</Col>*/}
                <Col xs={"auto"} className="btn-black">
                  <Btn
                    type="API"
                    btntext="정산대행 API 완료"
                    onClick={() => settleAgentCompleteAlert()}
                  />
                </Col>
                <Col xs={"auto"} className="btn-black">
                  <Btn
                    type="API"
                    btntext="정산대행 API 요청"
                    onClick={() => settleAgentRequestAlert()}
                  />
                </Col>
                <Col xs={"auto"} className="btn-black">
                  <Btn
                    type="API"
                    btntext="세금계산서 발행 엑셀 다운"
                    // onClick={() => openDownloadModal("TAX_BILL")}
                    onClick={handleClickTaxBillExcelDownload}
                  />
                </Col>
              </Row>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">상호명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          //   onChange={(e) =>
                          //     setRequest({
                          //       ...request,
                          //       storeName: e.target.value,
                          //     })
                          //   }
                          value={storeName}
                          onChange={({ target }) => setStoreName(target.value)}
                          onKeyDown={handleInputKeyDown}
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                        />
                      </Col>
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          onClick={handleSearchClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              <Row className="p-1 align-items-center justify-content-center">
                {/* <Col xs={12} lg={"auto"} className="my-3">
                  <Row className="align-items-center justify-content-center">
                    <Col xs={"auto"}>
                      <h5 className="mb-0">년도</h5>
                    </Col>
                    <Col xs={"auto"}>
                      <Form.Select aria-label="년도 선택">
                        <option value="2022">2022</option>
                      </Form.Select>
                    </Col>
                    <Col xs={"auto"}>
                      <h5 className="mb-0">주차</h5>
                    </Col>
                    <Col xs={"auto"}>
                      <Form.Select
                        onChange={(e) => setWeekOfYear(e.target.value)}
                      >
                        <option value=""> 주차 선택</option>
                        {Array.from({ length: 52 }, (v, i) => i + 1).map(
                          (v, i) => (
                            <option key={v} value={v}>
                              {v}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </Col>
                    <Col xs={"auto"}>
                      <Btn
                        type="API"
                        btntext="조회 하기"
                        // onClick={() => inquire()}
                      />
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </div>
            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr style={{ verticalAlign: "middle" }}>
                    <th>선택</th>
                    <th>정산년도</th>
                    <th>주차</th>
                    <th>정산(예정)일</th>
                    <th>정산 대행 상태</th>
                    <th>정산 시작일</th>
                    <th>정산 마감일</th>
                    <th>상호명</th>
                    <th>가게명</th>
                    <th>스토어 매출(A)</th>
                    <th>매출 수수료(B)</th>
                    <th>스토어 정산액(C)</th>
                    <th>세금계산서발행(예정)일</th>
                    {/*<th>플랫폼 수수료(D)</th>*/}
                    {/*<th>*/}
                    {/*  실제 결제액*/}
                    {/*  <br />*/}
                    {/*  (A + D)*/}
                    {/*</th>*/}
                  </tr>
                </thead>
                <tbody className="text-center" style={{ position: "relative" }}>
                  <SettlementBoard
                    page={page}
                    showingStoreName={showingStoreName}
                    setTotalPage={setTotalPage}
                    setTotalElements={setTotalElements}
                    checkedIdSet={checkedIdSet}
                    setCheckedIdSet={setCheckedIdSet}
                  />
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={totalPage}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {modal.showModal && (
        <CustomModal
          callbackOfOk={modal.callbackOfOk}
          textOfHeader={modal.textOfHeader}
          textOfBody={modal.textOfBody}
          setModal={setModal}
        />
      )}
    </>
  );
}
